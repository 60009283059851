import Image from 'next/image';

import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const WindowContainer = styled.div(
  ({ theme }) => `
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.background};
  display: flex;
  padding: ${theme.spacing[3]};
`,
);

export const ModalWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ModalTitleContainer = styled.div(
  ({ theme }) => `
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-block:${theme.spacing[1]};

  ${breakpoints.mobile}{
    flex-direction:column;
    gap:${theme.spacing[1]};
    margin-block:${theme.spacing[2]};
  }
`,
);

export const Title = styled.h1(
  ({ theme }) => `
  font-size:${theme.typography1.fontSize};
  font-weight:${theme.typography1.fontWeight};
`,
);

export const ImageQuantityTitle = styled.span(
  ({ theme }) => `
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
`,
);

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImagesContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing[2]};

  ${breakpoints.mobile} {
    justify-content: normal;
    gap: ${theme.spacing[1]};
  }
`,
);

export const BigImageWrapper = styled.div(
  ({ theme }) => `
  position: relative !important;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: start;

  img {
    display: inline-block;
    height: calc(100vh - 300px);
    border-radius: 5px;
    margin-right: ${theme.spacing[1]};
  }

  &::-webkit-scrollbar {
    margin-top:10px;
    width: ${theme.spacing[1]};
    height: ${theme.spacing[1]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.text};
    border-radius: calc(${theme.spacing[1]} - 4px);
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  ${breakpoints.mobile} {
    img {
      height: 320px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`,
);

export const BigImage = styled(Image)(
  ({ theme }) => `
  border-radius: calc(${theme.spacing[1]} - 4px);
  object-fit: cover;
  scroll-snap-align: start;
  cursor: pointer;
  width: auto;
  height: 100%`,
);

export const SmallImageWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 8px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;

  &::-webkit-scrollbar {
    width: ${theme.spacing[1]};
    height: ${theme.spacing[1]};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.text};
    border-radius: calc( ${theme.spacing[1]} - 4px);
  }
  &::-webkit-scrollbar-track {
    display: none;
  }

  ${breakpoints.mobile} {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`,
);

export const SmallImage = styled(Image)<{ $active: boolean }>(
  ({ theme, $active }) => `
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  position: relative !important;
  border-radius: calc( ${theme.spacing[1]} - 4px);
  border: ${$active ? '2px solid green' : '2px solid transparent'};
`,
);
