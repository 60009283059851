import { useQuery } from '@tanstack/react-query';

import { Coordinates } from '@/context/TerrainProvider';
import { AddReview, DetailedReviews, Terrain } from '@/lib/api-dtos';
import { ApiError } from '@/lib/api-error';
import { SortKeys } from '@/lib/detailedReviewSorter';
import { TerrainService } from '@/services/TerrainService';

export const useTerrains = (
  search: string | null,
  locationIds: number[],
  openAt: string,
  propertyIds: number[],
  typeIds: string[],
  kindIds: number[],
  coordinates: Coordinates | null,
) => {
  // error handling is not implemented yet
  const { data, isPending } = useQuery<Terrain[], ApiError>({
    queryKey: ['terrains', search, locationIds, openAt, propertyIds, typeIds, kindIds, coordinates],
    queryFn: () =>
      new TerrainService().list(
        search,
        locationIds,
        openAt,
        propertyIds,
        typeIds,
        kindIds,
        coordinates,
      ),
  });

  return { terrains: data, isPending };
};

export const useTerrain = (id: number | null) => {
  // error handling is not implemented yet
  const { data, isPending } = useQuery<Terrain | null, ApiError>({
    queryKey: ['terrain', id],
    queryFn: () => new TerrainService().details(id),
  });

  return { terrain: data, isPending };
};

export const useReviews = (id: number, sortKey: SortKeys) => {
  const terrainService = new TerrainService();
  // error handling is not implemented yet
  const { data, isPending, refetch } = useQuery<DetailedReviews[], ApiError>({
    queryKey: ['reviews', id, sortKey],
    queryFn: () => terrainService.reviews(id, sortKey),
  });

  const addReview = async (reviewData: AddReview) => {
    await terrainService.writeReview(id, reviewData);
    refetch();
  };

  return { reviews: data, isPending, addReview };
};
