import { useTranslation } from 'react-i18next';

import { useTerrain } from '@/hooks/useTerrains';
import {
  getDistanceForClosestOVstop,
  getDistanceForNextGroceryStore,
} from '@/utils/createFinderBy';

import {
  ContactDetails,
  DescriptionText,
  IconsContainer,
  InfosContainer,
  LocationContainer,
  LocationInformationItem,
  LocationInformationList,
  MainContainer,
  MoreInfo,
  Subtitle,
  Title,
} from './styles/LocationDetails';

interface LocationProps {
  id: number;
}

import FacebookIcon from '@images/facebook_icon.svg';
import InstagramIcon from '@images/instagram_icon.svg';

export function LocationDetails({ id }: LocationProps) {
  const { t } = useTranslation('common');
  const { isPending, terrain } = useTerrain(id);

  if (isPending || !terrain) return null;

  const distanceFromPublicTransportation = getDistanceForClosestOVstop(terrain.entity_properties);

  const distanceFromNearestGroceryStore = getDistanceForNextGroceryStore(terrain.entity_properties);

  // Function to encode address for URL to be used on google maps URL
  const encodeAddressForUrl = (address: string) => encodeURIComponent(address);

  // Concatenate the address to be encoded
  const address =
    `${terrain.main_address.street} ${terrain.main_address.number}` +
    `,${terrain.main_address.zipcode},${terrain.main_address.city};`;

  const encodedAddress = encodeAddressForUrl(address);

  //  Removes the "https://" or "http://" prefix from a URL
  const removeUrlPrefix = (url: string) => {
    return url.replace(/^https?:\/\//, '');
  };

  return (
    <LocationContainer>
      {/* Location Section */}
      <Title>{t('locationTitle')}</Title>
      <MainContainer>
        <InfosContainer>
          <ContactDetails>
            <span className="street">
              {terrain.main_address.street} {terrain.main_address.number}
            </span>
            <span>
              {terrain.main_address.zipcode} {terrain.main_address.city}
            </span>
            <span>{terrain.state.name}</span>
          </ContactDetails>
        </InfosContainer>
        {/* Skeleton */}
        <InfosContainer>
          <Subtitle>{t('locationContactDetails')}</Subtitle>
          <ContactDetails>
            <MoreInfo>
              <span>
                {terrain.manager_address.firstname || terrain.manager_address.description}
              </span>
              <span>{terrain.manager_address.telephone}</span>
              <a target="_blank" href={`mailto:${terrain.manager_address.email}`}>
                {terrain.manager_address.email}
              </a>
              <a target="_blank" href={terrain.website_url}>
                {removeUrlPrefix(terrain.website_url)}
              </a>
              <IconsContainer>
                <a href={terrain.facebook} target="_blank">
                  {terrain.facebook ? <FacebookIcon /> : ''}
                </a>
                <a href={terrain.instagram} target="_blank">
                  {terrain.instagram ? <InstagramIcon /> : ''}
                </a>
              </IconsContainer>
            </MoreInfo>
          </ContactDetails>
        </InfosContainer>
        <InfosContainer>
          <Subtitle>{t('locationDescription')}</Subtitle>
          <DescriptionText>{terrain.location}</DescriptionText>
        </InfosContainer>
        <InfosContainer>
          <Subtitle>{t('locationInformation')}</Subtitle>
          <LocationInformationList>
            {terrain.nearest_km && (
              <LocationInformationItem>
                {t('nearestNumberInformation')}
                <strong>{terrain.nearest_km} km</strong>
              </LocationInformationItem>
            )}
            {distanceFromPublicTransportation && (
              <LocationInformationItem>
                {t('distancePublictTrasnportationStop')}
                <strong>{distanceFromPublicTransportation} km</strong>
              </LocationInformationItem>
            )}
            {terrain.nearest_lf && (
              <LocationInformationItem>
                {t('distanceInformation')}
                <strong> {terrain.nearest_lf} km</strong>
              </LocationInformationItem>
            )}
            {distanceFromNearestGroceryStore && (
              <LocationInformationItem>
                {t('groceryStoreDistance')}
                <strong>{distanceFromNearestGroceryStore} km</strong>
              </LocationInformationItem>
            )}
            {terrain.nearest_law && (
              <LocationInformationItem>
                {t('junctionDistance')}
                <strong>{terrain.nearest_law} km</strong>
              </LocationInformationItem>
            )}
          </LocationInformationList>
        </InfosContainer>
        <InfosContainer>
          <Subtitle>{t('locationRoute')}</Subtitle>
          <DescriptionText>{terrain.route}</DescriptionText>
          <a
            href={`http://maps.google.nl/maps?q=${encodedAddress}`}
            target="_blank"
            className="route_button">
            {t('findYourRouteButton')}
          </a>
        </InfosContainer>
      </MainContainer>
    </LocationContainer>
  );
}
