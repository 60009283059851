import Image from 'next/image';

import styled from 'styled-components';

export const CardContainer = styled.div`
  color: ${(props) => props.theme.colors.text};
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
`;

export interface EntityImageProps {
  type: 'normal' | 'small';
}

export const EntityImageHolder = styled.div`
  border-radius: 4px;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
  background-color: #e9e9e9;
  position: relative;
`;
export const EntityImage = styled(Image)<EntityImageProps>`
  border-radius: 4px;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  aspect-ratio: ${(props) => (props.type === 'normal' ? 1.2 : 2.8)} !important;
  height: unset !important;
  margin: 0;
`;

export const Name = styled.h2`
  margin-top: 0.5rem;
  color: ${(props) => props.theme.colors.text};
  display: inline-block;
  font-weight: 600;
  font-size: ${(props) => props.theme.typography3.fontSize};
  line-height: 1.5rem;
`;
export const PlaceState = styled.span`
  display: inline-block;
  margin: 0;
  font-weight: 400;
  font-size: ${(props) => props.theme.typography4.fontSize};
  color: ${(props) => props.theme.colors.text};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MoreInfoBlock = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
`;

export const RatingContainer = styled.div`
  padding-top: 0.15rem;
  font-size: ${(props) => props.theme.typography4.fontSize};
  font-weight: 600;
  line-height: ${(props) => props.theme.typography4.fontSize};
  white-space: nowrap;

  & * {
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SingleRatingStar = styled(Image)`
  display: inline-block;
`;

export const RatingStar = styled(SingleRatingStar)`
  margin-inline: 1.5rem 0.25rem;
`;

export const GroupLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 6px 2px 6px;
  margin: 4px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.whiteText};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: 500;
  font-family: var(--font-poppins);
  line-height: normal;
`;
