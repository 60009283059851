import styled from 'styled-components';

export const DatesContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${theme.spacing[2]};
  margin-bottom:${theme.spacing[2]};
  gap:${theme.spacing[1]};
  border-radius: 4px;
  background: ${theme.colors.backgroundReviewsCard};
`,
);

export const DatesHeader = styled.span(
  ({ theme }) => `
  text-align: left;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography1.fontWeight};
`,
);

export const DatesLabel = styled.span(
  ({ theme }) => `
  text-align: left;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
`,
);
