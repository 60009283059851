import { ChangeEvent, KeyboardEvent, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'next/image';

import { t } from 'i18next';

import { Coordinates } from '@/context/TerrainProvider';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useLocations } from '@/hooks/useLocations';
import { MapBoxResult, useSearchMapBox } from '@/hooks/useSearchMapBox';

import {
  CloseButton,
  Input,
  InputContainer,
  SearchContainer,
  SearchPopup,
  SearchResult,
} from '../styles/SearchBox';

/**
 * @description Seacrh box component
 */

export const SearchBox = ({ className }: { className: string }) => {
  const { t } = useTranslation('common');
  const refInput = useRef<HTMLInputElement>(null);
  const {
    search,
    setSearch,
    coordinates,
    setCoordinates,
    setLocations,
    setTerrain,
    setShowMapOnMobile,
  } = useTerrainContext();
  const [focus, setFocus] = useState(false);
  const [keyword, setKeyword] = useState(
    search ?? (coordinates ? `${coordinates.latitude},${coordinates.longitude}` : ''),
  );
  const { locations: allLocations } = useLocations();
  const { results: resultsMapBox } = useSearchMapBox(keyword);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchOnKeywords();
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (event.target.value === '') {
      setSearch('');
      setCoordinates(null);
    }
  };

  const goToCurrenCoordinates = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      goToCoordinates(position.coords);
    });
  };

  const goToCoordinates = (coordinates: Coordinates, name?: string) => {
    setShowMapOnMobile(true);
    setLocations([]);
    setCoordinates(coordinates);
    setKeyword(name ?? `${coordinates.latitude},${coordinates.longitude}`);
    setSearch('');
    setTerrain(null);
    refInput.current?.blur();
  };

  const searchOnKeywords = () => {
    if (keyword.match(/[0-9.]+,[0-9.]+/i)) {
      const newCoordinates = keyword.split(',');
      if (newCoordinates[0] && newCoordinates[1]) {
        goToCoordinates({ latitude: +newCoordinates[0], longitude: +newCoordinates[1] });
      }
    } else {
      setSearch(keyword);
    }
    setTerrain(null);
    refInput.current?.blur();
  };

  const openSearchResult = (result: MapBoxResult) => {
    // look up province
    if (result.place_type === 'region') {
      const provinces = allLocations?.flatMap(({ children, ...rest }) =>
        children!.map((c) => ({ ...rest, ...c })),
      );

      const location = provinces?.find((loc) => loc.name === result.text);
      if (location) {
        setLocations([location.id]);
        setShowMapOnMobile(true);
        setKeyword(result.text);
        setSearch('');
        setTerrain(null);
        refInput.current?.blur();
        return;
      }
      return;
    }
    // lookup county
    if (result.place_type === 'country') {
      const location = allLocations?.find((loc) => loc.name === result.text);
      if (location) {
        setLocations(location.children!.map((c) => c.id));
        setShowMapOnMobile(true);
        setKeyword(result.text);
        setSearch('');
        setTerrain(null);
        refInput.current?.blur();
        return;
      }
    }
    // otherwise go to coordinates
    goToCoordinates(result.coordinates, result.text);
  };

  // Clear input if there is any text there
  const clearInput = () => {
    setKeyword('');
    setSearch('');
    setCoordinates(null);
    setTerrain(null);
    refInput.current?.focus();
  };

  const containerFocusClassName = focus ? 'focus' : '';
  return (
    <SearchContainer className={`${className} ${containerFocusClassName} `}>
      <InputContainer>
        <Image
          src={'/assets/images/search-outline.svg'}
          width={16}
          height={16}
          alt={t('magnifyingGlass')}
          style={{ marginRight: '0.5rem' }}
        />

        <Input
          ref={refInput}
          type="text"
          placeholder={t('inputSearchPlaceholder')}
          value={keyword}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
        />
        {keyword && (
          <CloseButton onClick={clearInput}>
            <Image
              src={'/assets/images/close-outline.svg'}
              width={28}
              height={28}
              alt="clear-icon"
              style={{ cursor: 'pointer' }}
            />
          </CloseButton>
        )}
      </InputContainer>
      {focus && (
        <SearchPopup>
          <SearchResultItem
            onClick={() => goToCurrenCoordinates()}
            mainText={<strong>{t('currentLocation')}</strong>}
            secondaryText={t('currentLocationDescription')}
          />
          {resultsMapBox.map((result) => (
            <SearchResultItem
              key={result.id}
              onClick={() => openSearchResult(result)}
              mainText={<strong>{result.text}</strong>}
              secondaryText={result.place}
            />
          ))}
          {keyword.length > 0 && (
            <SearchResultItem
              onClick={() => searchOnKeywords()}
              mainText={
                <>
                  {t('searchFor')} <strong>&quot;{keyword}&quot;</strong>
                </>
              }
              secondaryText={t('filterAllResults')}
            />
          )}
        </SearchPopup>
      )}
    </SearchContainer>
  );
};

type SearchResultItemProps = {
  onClick: () => void;
  mainText: ReactNode;
  secondaryText: ReactNode;
};

function SearchResultItem({ onClick, mainText, secondaryText }: SearchResultItemProps) {
  return (
    <SearchResult onMouseDown={(e) => e.preventDefault()} onClick={onClick}>
      <span>
        <Image
          className="icon"
          src={'/assets/images/location.svg'}
          width={16}
          height={16}
          alt={t('locationIconAlt')}
        />
        {mainText}
      </span>
      <span>{secondaryText}</span>
    </SearchResult>
  );
}
