import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '@/hooks/useIsMobile';

import {
  ButtonContainer,
  DescriptionContainer,
  TextContainer,
  ToggleDescriptionButton,
} from './styles';

/**
 * @description Render Terrain Description component
 * In mobile show first two sentences
 *
 */

interface TerrainDescriptionProps {
  text: string;
}

export function TerrainDescription({ text }: TerrainDescriptionProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('common');
  const [showFullText, setShowFullText] = useState(false);

  // Transform text to display first 2 sentence
  // Match sentences with all punctuation
  const sentences = text?.match(/[^.!?]+[.!?]+/g);
  const firstTwoSentences = sentences
    ?.slice(0, 2)
    .map((sentence) => sentence.trim())
    .join(' ');

  // Toogle to show or hide the full text
  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <DescriptionContainer>
      {isMobile ? (
        <>
          <TextContainer>
            {firstTwoSentences}
            {showFullText && text.slice(firstTwoSentences?.length)}
          </TextContainer>
          <ButtonContainer>
            <ToggleDescriptionButton onClick={toggleShowFullText}>
              {showFullText ? `${t('readLessButton')}..` : `${t('readMoreButton')}..`}
            </ToggleDescriptionButton>
          </ButtonContainer>
        </>
      ) : (
        <>
          <TextContainer>{text}</TextContainer>
        </>
      )}
    </DescriptionContainer>
  );
}
