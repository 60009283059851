import styled from 'styled-components';

export const IconDiv = styled.div`
  display: flex;
`;

export const Icon = styled.span`
  font-family: var(--font-icon);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;
