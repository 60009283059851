import StarIcon from '@images/ratingStar.svg';
import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const HeaderContainer = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  width:100%;
  margin-block:${theme.spacing[2]};
  margin-top:0;
`,
);

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.mobile} {
    align-items: baseline;
  }
`;

export const HeaderLabel = styled.h1(
  ({ theme }) => `
  font-family: var(--font-lexend);
  color: ${theme.colors.text};
  text-align: left;
  font-size:${theme.typography1.fontSize};
  font-weight:${theme.typography1.fontWeight};
  line-height: ${theme.typography1.lineHeight};
`,
);

export const HeaderContent = styled.div(
  ({ theme }) => `
  display: flex;
  margin-top:${theme.spacing[1]};
  align-items: center;
  gap: ${theme.spacing[1]};
`,
);

export const CityContent = styled.div`
  display: flex;
  align-items: center;
`;

export const CityLabel = styled.span(
  ({ theme }) => `
  text-align: left;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
`,
);

export const ReserveButton = styled.button(
  ({ theme }) => `
  display:flex;
  align-items: center;
  height:32px;
  background-color: ${theme.colors.primary};
  border-radius: 25px;
  border: none;
  cursor: pointer;
  padding: 6px ${theme.spacing[2]}; 
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: ${theme.typography5.fontSize};
  font-weight: ${theme.typography5.fontWeight};
  line-height: 140%;
  
  &:hover {
    color: ${theme.colors.text};
  }
`,
);

export const CampingPlaces = styled.span(
  ({ theme }) => `
  color: #737373;
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
`,
);

export const DotContainer = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-weight: ${theme.typography4.fontWeight};
`,
);

export const ReviewContainer = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  font-size: ${theme.typography4.fontSize};
  font-weight: 600;
  line-height: ${theme.typography4.fontSize};
  white-space: nowrap;
  gap:4px;

  & span {
    margin-left:2px;
    display: inline-block;
    vertical-align: middle;
  }
`,
);

export const RatingStar = styled(StarIcon)`
  display: inline-block;
  margin-bottom: 2px;
`;

export const NumberContainer = styled.span(
  ({ theme }) => `
  margin-left:2px;
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography1.fontWeight};
  `,
);

export const OpenDatesContainer = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: ${theme.spacing[1]};
`,
);

export const OpenDatesTitle = styled.span(
  ({ theme }) => `
  font-size: ${theme.typography3.fontSize};
  font-weight: ${theme.typography3.fontWeight};
  `,
);
