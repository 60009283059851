'use client';

import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { useRouter, useSearchParams } from 'next/navigation';

export interface Coordinates {
  longitude: number;
  latitude: number;
}

export interface TerrainContextType {
  terrain: number | null;
  setTerrain: (terrain: number | null) => void;
  showMapOnMobile: boolean;
  setShowMapOnMobile: (showMapOnMobile: boolean) => void;
  lockFilterScroll: boolean;
  setLockFilterScroll: (lockFilterScroll: boolean) => void;
  isListOpen: boolean;
  setIsListOpen: (isListOpen: boolean) => void;
  search: string | null;
  setSearch: (search: string) => void;
  locations: number[];
  setLocations: (locations: number[]) => void;
  coordinates: Coordinates | null;
  setCoordinates: (coordinates: Coordinates | null) => void;
  openAt: string;
  setOpenAt: (openAt: string) => void;
  properties: number[];
  setProperties: (properties: number[]) => void;
  routes: number[];
  setRoutes: (routes: number[]) => void;
  types: string[];
  setTypes: (types: string[]) => void;
  kinds: number[];
  setKinds: (kinds: number[]) => void;
}

const defaults: TerrainContextType = {
  terrain: null,
  setTerrain: () => {},
  showMapOnMobile: false,
  setShowMapOnMobile: () => {},
  lockFilterScroll: false,
  setLockFilterScroll: () => {},
  isListOpen: true,
  setIsListOpen: () => {},
  search: null,
  setSearch: () => {},
  locations: [],
  setLocations: () => {},
  coordinates: null,
  setCoordinates: () => {},
  openAt: '',
  setOpenAt: () => {},
  properties: [],
  setProperties: () => {},
  routes: [],
  setRoutes: () => {},
  types: [],
  setTypes: () => {},
  kinds: [],
  setKinds: () => {},
};
export const TerrainContext = createContext<TerrainContextType>(defaults);

export interface TerrainProvider {
  children: ReactNode;
  landingPage?: boolean;
}

export const TerrainProvider = ({ children, landingPage = false }: TerrainProvider) => {
  const router = useRouter();

  // get defaults from query string on full reload
  const searchParams = useSearchParams();
  const terrainId = searchParams?.get('terrain');
  const searchKeyword = searchParams?.get('search');
  const locationIds = searchParams
    ?.get('locations')
    ?.split(',')
    .map((loc) => +loc);
  const locationIdsLegacy = searchParams?.getAll('state_id[]').map((id) => +id);

  const searchCoordinates = searchParams
    ?.get('coordinates')
    ?.split(',')
    ?.reduce((a, v, i) => ({ ...a, [i === 0 ? 'latitude' : 'longitude']: v }), {
      latitude: 0,
      longitude: 0,
    });
  const openAtQuery = searchParams?.get('openAt');
  const propertyIds = searchParams
    ?.get('properties')
    ?.split(',')
    .map((prop) => +prop);
  const propertyIdsLegacy = [
    ...(searchParams?.getAll('property_id[]') ?? []),
    ...(searchParams?.getAll('property_id[0]') ?? []),
  ].map((id) => +id);

  const routeIds = searchParams
    ?.get('routes')
    ?.split(',')
    .map((r) => +r);

  const typeIds = searchParams?.get('types')?.split(',');

  const kindIds = searchParams
    ?.get('kinds')
    ?.split(',')
    .map((kindType) => +kindType);

  const [terrain, setTerrain] = useState(terrainId ? +terrainId : defaults.terrain);
  const [showMapOnMobile, setShowMapOnMobile] = useState(defaults.showMapOnMobile);
  const [lockFilterScroll, setLockFilterScroll] = useState(defaults.lockFilterScroll);
  const [isListOpen, setIsListOpen] = useState(defaults.isListOpen);
  const [search, setSearch] = useState(searchKeyword ?? defaults.search);
  const [locations, setLocations] = useState(
    locationIds ?? locationIdsLegacy ?? defaults.locations,
  );
  const [coordinates, setCoordinates] = useState(searchCoordinates ?? defaults.coordinates);
  const [openAt, setOpenAt] = useState(openAtQuery ?? defaults.openAt);
  const [properties, setProperties] = useState(
    propertyIds ?? propertyIdsLegacy ?? defaults.properties,
  );
  const [routes, setRoutes] = useState(routeIds ?? defaults.routes);
  const [types, setTypes] = useState(typeIds ?? defaults.types);
  const [kinds, setKinds] = useState(kindIds ?? defaults.kinds);

  // push query string to router
  useEffect(() => {
    const params: Record<string, string> = {};
    if (terrain) params.terrain = String(terrain);
    if (locations.length > 0) params.locations = locations.join(',');
    if (search) params.search = search;
    if (coordinates) params.coordinates = `${coordinates.latitude},${coordinates.longitude}`;
    if (openAt) params.openAt = openAt;
    if (properties.length > 0) params.properties = properties.join(',');
    if (routes.length > 0) params.routes = routes.join(',');
    if (types.length > 0) params.types = types.join(',');
    if (kinds.length > 0) params.kinds = kinds.join(',');
    const searchParams = new URLSearchParams(params);
    if (!landingPage) router.push(`?${searchParams.toString()}`, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terrain, locations, search, coordinates, openAt, properties, routes, types, kinds]);

  // Hooks
  const value = useMemo(
    () => ({
      terrain,
      setTerrain,
      showMapOnMobile,
      setShowMapOnMobile,
      lockFilterScroll,
      setLockFilterScroll,
      search,
      setSearch,
      locations,
      setLocations,
      coordinates,
      setCoordinates,
      isListOpen,
      setIsListOpen,
      openAt,
      setOpenAt,
      properties,
      setProperties,
      routes,
      setRoutes,
      types,
      setTypes,
      kinds,
      setKinds,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      router,
      terrain,
      setTerrain,
      showMapOnMobile,
      setShowMapOnMobile,
      lockFilterScroll,
      setLockFilterScroll,
      search,
      setSearch,
      locations,
      setLocations,
      coordinates,
      setCoordinates,
      isListOpen,
      setIsListOpen,
      openAt,
      setOpenAt,
      properties,
      setProperties,
      routes,
      setRoutes,
      types,
      setTypes,
      kinds,
      setKinds,
    ],
  );

  return <TerrainContext.Provider value={value}>{children}</TerrainContext.Provider>;
};
