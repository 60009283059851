import { EntityProperties } from '@/lib/api-dtos';

// Find the km from the closest public Transportation and grocery
// store based on the id coming from the properties array

const NEAREST_GROCERY_STORE = 'Afstand dichtstbijzijnde levensmiddelenwinkel';

const PUBLIC_TRANSPORTATION_ID = 'Afstand halte openbaar vervoer';

export const getDistanceForClosestOVstop = createFinderBy(PUBLIC_TRANSPORTATION_ID);

export const getDistanceForNextGroceryStore = createFinderBy(NEAREST_GROCERY_STORE);

function createFinderBy(entityPropertyId: string) {
  const findById = (entityProperty: EntityProperties) =>
    entityProperty.property.short_name?.includes(entityPropertyId);

  return (entityProperties: EntityProperties[]) => entityProperties.find(findById)?.extra;
}
