import styled from 'styled-components';

export const ButtonWrapper = styled.div<{ $landingPage: boolean }>(
  ({ theme, $landingPage = false }) => `
  display: flex;
  justify-content: flex-end;
  max-width: calc(800px - ${theme.spacing[4]});
  margin-left: ${$landingPage ? 'auto' : '0'};
  margin-right: ${$landingPage ? 'auto' : '0'};
`,
);

export const BackOnTopButton = styled.button(
  ({ theme }) => `
  margin-block:${theme.spacing[5]};
  padding: 0.5rem 1rem;
  border-radius: 25px;
  border: 1px solid rgba(18, 59, 43, 0.2);
  background: ${theme.colors.buttonBackground};
  color: ${theme.colors.text};
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
  cursor: pointer;
  z-index: 999;
`,
);

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ButtonText = styled.span`
  display: flex;
  align-self: center;
`;
