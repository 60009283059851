import styled from 'styled-components';

export const PropertyContainer = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  width:100%;
  max-width:800px;
  margin-top:${theme.spacing[1]};
`,
);

export const PropertyContent = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  margin-top:${theme.spacing[3]};
`,
);

export const Title = styled.h1(
  ({ theme }) => `
  margin-top:${theme.spacing[2]};
  font-family : var(--font-lexend);
  color: ${theme.colors.primary};
  text-align: left;
  font-size:${theme.typography2.fontSize};
  font-weight:${theme.typography5.fontWeight};
`,
);
