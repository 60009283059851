import { useTranslation } from 'react-i18next';

import { useIsMobile } from '@/hooks/useIsMobile';
import { useReviews } from '@/hooks/useTerrains';
import { EntityProperties } from '@/lib/api-dtos';
import { roundAverageRating } from '@/utils/roundAverageRating';

import { IconsSection } from '../HeaderIconSection';
import { PropertyItem } from '../PropertySection/propertyUtils';
import {
  CampingPlaces,
  CityContent,
  CityLabel,
  DotContainer,
  HeaderContainer,
  HeaderContent,
  HeaderLabel,
  NumberContainer,
  OpenDatesContainer,
  OpenDatesTitle,
  RatingStar,
  ReserveButton,
  ReviewContainer,
  TitleContainer,
} from './styles';

/**
 * @description Header Title component that render terrain name,
 * reservation button ( if reservation url exist ), city and quantity of availbale places
 */

interface HeaderTitleProps {
  name: string;
  reservation_url?: string;
  city: string;
  state: string;
  camping_places?: number;
  id: number;
  property: (PropertyItem | EntityProperties)[];
  open_from: string;
  open_till: string;
  kind: string;
}

export function HeaderTitle({
  name,
  reservation_url,
  city,
  state,
  camping_places,
  id,
  property,
  open_from,
  open_till,
  kind,
}: HeaderTitleProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('common');
  const { reviews } = useReviews(id, 'hightoLow');

  // Open Reservation Link in anotehr window
  const openLinkInNewWindow = (url: string | undefined) => {
    const handleClick = () => {
      if (url) {
        window.open(url, '_blank');
      } else {
        console.error('Invalid URL');
      }
    };

    return handleClick;
  };

  return (
    <HeaderContainer>
      {isMobile ? (
        <>
          <HeaderLabel>{name}</HeaderLabel>
          <TitleContainer>
            <CityContent>
              <CityLabel>
                {city}, {state}
              </CityLabel>
            </CityContent>
            {/* Show reserve link if it exist  */}
            {reservation_url && (
              <ReserveButton role="button" onClick={openLinkInNewWindow(reservation_url)}>
                {t('reserve')}
              </ReserveButton>
            )}
          </TitleContainer>
          <HeaderContent>
            {/* Header Icon Section */}
            <IconsSection property={property} />
            <DotContainer>•</DotContainer>
            {camping_places && (
              <>
                <CampingPlaces>{camping_places} plaatsen</CampingPlaces>
              </>
            )}
            {/* Average review */}
            {reviews && reviews.length >= 1 ? (
              <>
                <DotContainer>•</DotContainer>
                <ReviewContainer>
                  <RatingStar />
                  <NumberContainer>{roundAverageRating(reviews)}</NumberContainer>
                </ReviewContainer>
              </>
            ) : (
              <></>
            )}
          </HeaderContent>
          {/* Open dates */}
          <OpenDatesContainer>
            <OpenDatesTitle>
              Open: {open_from} - {open_till}
            </OpenDatesTitle>
            <DotContainer>•</DotContainer>
            {/* Terrain type */}
            <OpenDatesTitle>{kind}</OpenDatesTitle>
          </OpenDatesContainer>
        </>
      ) : (
        <>
          <TitleContainer>
            <HeaderLabel>{name}</HeaderLabel>
            {/* Show reserve link if it exist  */}
            {reservation_url && (
              <ReserveButton role="button" onClick={openLinkInNewWindow(reservation_url)}>
                {t('reserve')}
              </ReserveButton>
            )}
          </TitleContainer>
          <HeaderContent>
            <CityContent>
              <CityLabel>
                {city}, {state}
              </CityLabel>
            </CityContent>
            <DotContainer>•</DotContainer>
            {/* Header Icon Section */}
            <IconsSection property={property} />
            <DotContainer>•</DotContainer>
            {camping_places && (
              <>
                <CampingPlaces>{camping_places} plaatsen</CampingPlaces>
              </>
            )}
            {/* Average review */}
            {reviews && reviews.length >= 1 ? (
              <>
                <DotContainer>•</DotContainer>
                <ReviewContainer>
                  <RatingStar />
                  <NumberContainer>{roundAverageRating(reviews)}</NumberContainer>
                </ReviewContainer>
              </>
            ) : (
              <></>
            )}
            {/* Open dates */}
          </HeaderContent>
          <OpenDatesContainer>
            <OpenDatesTitle>
              Open: {open_from} - {open_till}
            </OpenDatesTitle>
            <DotContainer>•</DotContainer>
            {/* Terrain type */}
            <OpenDatesTitle>{kind}</OpenDatesTitle>
          </OpenDatesContainer>
        </>
      )}
    </HeaderContainer>
  );
}
