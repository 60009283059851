import styled from 'styled-components';

export const Page = styled.div(
  ({ theme }) => `
  display: flex;
  flex: 1 0 0;
  background-color: ${theme.colors.background};
  align-items: stretch;
  height: calc(100% - ${theme.dimensions.headerHeight + theme.dimensions.filterHeight}px)
`,
);
