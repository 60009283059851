import styled from 'styled-components';

export const PricingContainer = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  width:100%;
  max-width:800px;
  margin-block:${theme.spacing[5]};
`,
);

export const Title = styled.h1(
  ({ theme }) => `
  font-family : var(--font-lexend);
  color: ${theme.colors.primary};
  text-align: left;
  font-size:${theme.typography2.fontSize};
  font-weight:${theme.typography5.fontWeight};
`,
);

export const PricingContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.span(
  ({ theme }) => `
  font-family : var(--font-lexend);
  color: ${theme.colors.primary};
  text-align: left;
  font-size:${theme.typography6.fontSize};
  font-weight:${theme.typography5.fontWeight};
  margin-bottom : ${theme.spacing[1]}
`,
);

export const HighSeasonContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top:${theme.spacing[1]};
`,
);

export const HighSeasonContent = styled.div(
  ({ theme }) => `
  color:${theme.colors.text};
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
  line-height:${theme.typography3.lineHeight};
`,
);

export const ShowButton = styled.button(
  ({ theme }) => `
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  color: ${theme.colors.text};
  font-size: ${theme.typography5.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height:${theme.typography4.lineHeight};
`,
);

export const ExplanationSection = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.typography5.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  margin-bottom: ${theme.spacing[3]};
`,
);

export const CommentPriceSection = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  margin-bottom: ${theme.spacing[3]};
`,
);
