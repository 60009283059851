import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PhotoIcon from '@images/photos.svg';

import { useIsMobile } from '@/hooks/useIsMobile';
import { getImageUrl } from '@/utils/getImageUrl';

import { ImageGallery } from '../ImageGalery';
import {
  BigImage,
  ButtonContent,
  CustomImage,
  CustomImageHolder,
  ImageWrapper,
  ShowAllButton,
  SmallImages,
} from './styles';

/**
 * @description Responsive Image Grid component with the LightBox
 */

export interface ImageData {
  name: string;
  alt_text: string;
  large_url: string;
  thumb_url: string;
}

export interface ImageComponentProps {
  id: number;
  title: string;
  images?: ImageData[];
}

export function ImageComponent({ id, title, images }: ImageComponentProps) {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const [galleryOpen, setGalleryOpen] = useState(false);

  // Open image gallery
  const openGallery = () => {
    setGalleryOpen(true);
  };

  // Close image gallery
  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const imagesToShow = isMobile ? 3 : 5;

  // Handle the case where `images` is not an array
  if (!images || !Array.isArray(images)) {
    // Render skeleton
    return (
      <ImageWrapper>
        <BigImage $isMobile={isMobile}>
          <CustomImageHolder>
            <CustomImage
              priority={true}
              fill
              sizes="(max-width: 600px) 100vw, 50vw"
              src={'/assets/images/placeholder.png'}
              alt={''}
            />
          </CustomImageHolder>
        </BigImage>
        {[...Array(imagesToShow - 1).keys()].map((image, index) => (
          <SmallImages $isMobile={isMobile} key={index}>
            <CustomImageHolder>
              <CustomImage
                priority={true}
                fill
                sizes="(max-width: 600px) 100vw, 50vw"
                src={'/assets/images/placeholder.png'}
                alt={''}
              />
            </CustomImageHolder>
          </SmallImages>
        ))}
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper>
      <BigImage $isMobile={isMobile}>
        {images[0] && (
          <CustomImageHolder>
            <CustomImage
              priority={true}
              fill
              sizes="(max-width: 600px) 100vw, 50vw"
              src={getImageUrl(images[0].large_url)}
              alt={images[0].alt_text}
              onClick={openGallery}
            />
            <ShowAllButton onClick={openGallery}>
              <ButtonContent>
                <PhotoIcon />
                {t('showAllPhoto')} ({images.length})
              </ButtonContent>
            </ShowAllButton>
          </CustomImageHolder>
        )}
      </BigImage>
      {images.slice(1, imagesToShow).map((image, index) => (
        <SmallImages key={index} $isMobile={isMobile}>
          <CustomImageHolder>
            <CustomImage
              priority={true}
              fill
              sizes="(max-width: 600px) 100vw, 50vw"
              src={getImageUrl(image.large_url)}
              alt={image.alt_text || image.name}
              onClick={openGallery}
            />
          </CustomImageHolder>
        </SmallImages>
      ))}
      {galleryOpen && (
        <ImageGallery
          onClose={closeGallery}
          id={id}
          title={title}
          imageQuantity={images.length}
          images={images}
        />
      )}
    </ImageWrapper>
  );
}
