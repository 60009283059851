import styled from 'styled-components';

export const ReviewsContainer = styled.div`
  --gap: 0.5rem;
  --border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
  & span {
    font-size: ${(props) => props.theme.typography3.fontSize};
  }

  & h2 {
    font-size: ${(props) => props.theme.typography2.fontSize};
    color: ${(props) => props.theme.colors.primary};
    margin-right: 1rem;
  }
`;

export const SortDropdown = styled.div`
  --width: 8rem;
  --height: 1.25rem;
  position: relative;
  border: none;
  margin-right: var(--gap);
  color: ${(props) => props.theme.colors.text};
`;

export const SortButton = styled.button`
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  width: var(--width);
  height: var(--height);
  border: none;
  padding-inline: var(--gap);
  align-items: center;
  border-radius: var(--border-radius);
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: 400;
  // button do not inherit the font from the site
  font-family: var(--font-poppins);
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundReviewsCard};
  }
`;

export const ChangeSortButton = styled(SortButton)`
  order: 2;

  & svg {
    visibility: hidden;
  }

  &.selected {
    // make the selected comes before the other
    order: 1;

    svg {
      visibility: visible;
      transform: scaleY(-1);
    }
  }
`;

export const SortButtons = styled.div`
  --border: 1px;
  --position: calc(0rem - var(--gap) - var(--border));
  position: absolute;
  top: var(--position);
  left: var(--position);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: var(--border-radius);
  gap: var(--gap);
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0 4px 4px 0 #00000014;
  border: solid var(--border) #123b2b33;
  padding: var(--gap);
`;

export const ReviewsCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--gap) 0;
  background-color: ${(props) => props.theme.colors.backgroundReviewsCard};
  justify-content: space-around;
  padding: 1rem;
  border-radius: var(--border-radius);
`;

export const NoReviewsCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  background-color: ${(props) => props.theme.colors.backgroundReviewsCard};
  justify-content: space-around;
  padding: 1rem;
  border-radius: var(--border-radius);
`;

export const AuthorName = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  & strong {
    margin-right: var(--gap);
  }
`;
export const ReviewDate = styled.span`
  display: block;
  font-size: ${(props) => props.theme.typography4.fontSize};
  color: ${(props) => props.theme.colors.text};
`;

export const ReviewDescription = styled.p`
  display: block;
  font-size: ${(props) => props.theme.typography3.fontSize};
  line-height: 24px;
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text};
`;
export const StarsBlock = styled.div`
  display: block;
`;
export const ReadMoreButton = styled.button`
  display: block;
  width: 5rem;
  text-align: left;
  color: ${(props) => props.theme.colors.text};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding-top: 4px;
`;
export const ReviewsCardFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShowMoreButton = styled.button`
  display: block;
  width: 7.4375rem;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  cursor: pointer;
`;
