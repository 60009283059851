import { Coordinates } from '@/context/TerrainProvider';
import { AddReview, DetailedReviews, Terrain } from '@/lib/api-dtos';
import { sortFunctions, SortKeys } from '@/lib/detailedReviewSorter';
import fetcher, { FetchParams } from '@/utils/fetcher';

export interface TerrainParams extends Record<string, string> {
  terrain: string; // search
  state_id: string; // location/provence
  open_at: string; //date filter
  property_id: string; // camping equipment filter
  type: string; //type terrain filter
  kind: string; //kind type terrain filter
}

export class TerrainService {
  async list(
    search: string | null,
    locationIds: number[],
    openAt: string,
    properttyIds: number[],
    typeIds: string[],
    kindIds: number[],
    coordinates: Coordinates | null,
  ): Promise<Terrain[]> {
    const params: Record<string, string> = {};
    if (search) params.terrain = search;
    if (openAt) params.open_at = openAt;
    if (coordinates) {
      params.latitude = String(coordinates.latitude);
      params.longitude = String(coordinates.longitude);
      params.range = String(15);
    }
    const searchParams = new URLSearchParams(params);
    for (const locationId of locationIds) {
      searchParams.append('state_id[]', String(locationId));
    }
    for (const propertyId of properttyIds) {
      searchParams.append('property_id[]', String(propertyId));
    }
    for (const typeId of typeIds) {
      searchParams.append('type[]', String(typeId));
    }
    for (const kindId of kindIds) {
      searchParams.append('kind[]', String(kindId));
    }
    return fetcher<Terrain[]>({
      url: `/api/proxy/entities?${searchParams.toString()}`,
    });
  }

  async details(id: number | null): Promise<Terrain | null> {
    if (!id) return null;

    try {
      const terrain = await fetcher<Terrain>({
        url: `/api/proxy/terrains/${id}?viewer=site`,
      });
      return terrain;
    } catch (error) {
      // If not details are found, try group details
      return fetcher<Terrain>({
        url: `/api/proxy/group_terrains/${id}?viewer=site`,
      });
    }
  }

  async reviews(id: number, sortKey: SortKeys): Promise<DetailedReviews[]> {
    try {
      const detailedReviews = await fetcher<DetailedReviews[]>({
        url: `/api/proxy/entities/${id}/reviews?viewer=site`,
      });

      return detailedReviews.sort(sortFunctions[sortKey]);
    } catch (error) {
      return [];
    }
  }

  async writeReview(id: number | null, reviewData: AddReview): Promise<AddReview> {
    const params: FetchParams = {
      url: `/api/proxy/entities/${id}/reviews.json?token=${reviewData.token}`,
      method: 'POST',
      body: reviewData,
    };

    return fetcher<AddReview>(params);
  }
}
