import styled from 'styled-components';

export const DescriptionContainer = styled.div(
  ({ theme }) => `
  position: relative;
  margin-block: ${theme.spacing[2]};
`,
);

export const TextContainer = styled.span(
  ({ theme }) => `
  overflow: hidden;
  font-size: ${theme.typography3.fontSize};
  font-weight: ${theme.typography3.fontWeight};
  line-height: ${theme.typography3.lineHeight};
`,
);

export const ButtonContainer = styled.div(
  ({ theme }) => ` 
  margin-top: ${theme.spacing[2]};
  display: flex;
  justify-content: center;
`,
);

export const ToggleDescriptionButton = styled.button(
  ({ theme }) => ` 
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: ${theme.typography4.fontSize};
  font-style: normal;
  font-weight: ${theme.typography4.fontWeight};
  line-height: ${theme.typography4.lineHeight};
`,
);
