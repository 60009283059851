import React, { useState } from 'react';

import RatingStar from '@images/ratingStar.svg';
import RatingStarNotFilled from '@images/ratingStarNotFilled.svg';

import { RatingButton, StarRatingContainer } from './styles/RatingStarContainer';

interface RatingStarProps {
  onChange: (value: number) => void;
  value: number;
}
export function RatingStarComponent({ onChange, value }: RatingStarProps) {
  const [hover, setHover] = useState<number | null>(null);

  const handleMouseEnter = (ratingValue: number) => {
    setHover(ratingValue);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  const handleClick = (ratingValue: number, event: React.MouseEvent<HTMLButtonElement>) => {
    onChange(ratingValue);
    event.stopPropagation(); // Stop event propagation here
    event.preventDefault(); // Prevent default button behavior
  };

  return (
    <StarRatingContainer>
      {[...Array(5)].map((_, i) => {
        const ratingValue: number = i + 1;
        return (
          <RatingButton
            key={i}
            onClick={(event) => handleClick(ratingValue, event)}
            onMouseEnter={() => handleMouseEnter(ratingValue)}
            onMouseLeave={handleMouseLeave}>
            {ratingValue <= (hover || value!) ? (
              <RatingStar width="16px" height="16px" />
            ) : (
              <RatingStarNotFilled width="16px" height="16px" />
            )}
          </RatingButton>
        );
      })}
    </StarRatingContainer>
  );
}
