import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const AddReviewsContent = styled.form`
  background-color: ${(props) => props.theme.colors.backgroundReviewsCard};
  border-radius: var(--borderRaidus);
  padding: 1rem;
`;
export const AddReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const AddReviewTitle = styled.div`
  font-size: ${(props) => props.theme.typography6.fontSize};
  font-weight: 600;
  font-family: var(--font-lexend);
  color: ${(props) => props.theme.colors.primary};
  height: 1.8125rem;
`;
export const Input = styled.input`
  --borderRadius: 4px;
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: column;
  border: none;
  width: 24.875rem;
  height: 2rem;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: var(--borderRadius);
  margin-bottom: 1rem;

  ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const InputLabel = styled.label`
  display: block;
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-size: ${(props) => props.theme.typography3.fontWeight};
  font-family: var(--font-poppins);
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 25px;
  padding: 6px 16px;
  width: 12.25rem;
  height: 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.whiteText};
  font-size: ${(props) => props.theme.typography4.fontSize};
  font-weight: 600;

  &:hover {
    color: ${(props) => props.theme.colors.text};
  }
`;

export const ReviewTextArea = styled.textarea`
  display: block;
  width: 24.875rem;
  height: 5rem;
  border-radius: var(--borderRaidus);
  border: 1px solid rgba(217, 217, 217, 1);

  ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const RatingMessage = styled.span`
  display: block;
`;

export const EmailMessage = styled.span`
  display: block;
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: 400;
  font-family: var(--font-poppins);
`;

export const FeedbackMessage = styled.span`
  display: block;
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: 400;
  font-family: var(--font-poppins);
  color: ${(props) => props.theme.colors.primary};
`;

export const ConfirmationMessageContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size:${theme.typography1.fontSize};
  font-weight:${theme.typography1.fontWeight};
  line-height: ${theme.typography1.lineHeight};
`,
);

export const ConfirmationMessage = styled.span<{ $title: boolean }>(
  ({ theme, $title }) => `
  font-size: ${theme.typography3.fontSize};
  font-weight: ${$title ? theme.typography1.fontWeight : theme.typography3.fontWeight};
  color: ${theme.colors.text};
`,
);
