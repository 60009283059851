import { useTranslation } from 'react-i18next';

import CloseButtonIcon from '@images/CloseButtonIcon.svg';

import { ButtonContainer, ButtonText } from './styles';

/**
 * @description Close Button for landing page
 */

export function CloseButtonLandingPage() {
  const { t } = useTranslation('common');
  return (
    <ButtonContainer>
      <CloseButtonIcon />
      <ButtonText>{t('closeButtonLandingPage')}</ButtonText>
    </ButtonContainer>
  );
}
