import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const SearchContainer = styled.div`
  --padding-vertical: 2px;
  --height: 26px;
  --border-radius: 1rem;
  --outline: solid 1px ${(props) => props.theme.colors.borderColor};
  --box-shadow: 1px 1px 4px 0px ${(props) => props.theme.colors.shadow};
  width: 100%;
  justify-self: center;
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  grid-area: searchBar;
  flex-direction: column;
  position: relative;
  border: 1px;
  border-radius: var(--border-radius);
  outline: var(--outline);
  box-shadow: var(--box-shadow);
  z-index: 2000;

  ${breakpoints.tabletOrBigger} {
    max-width: 25rem;
  }

  &.focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${(props) => props.theme.colors.background};
  }

  ${breakpoints.tablet} {
    &.focus {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw !important;
      border-radius: 0;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(--padding-vertical) 15px;

  &.showing-results {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Input = styled.input`
  width: 100%;
  justify-self: center;
  background-color: ${(props) => props.theme.colors.secondary};
  font-size: 1em;
  border: none;
  outline: none;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text};
  height: var(--height);

  &::placeholder {
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.typography4.fontSize};
    font-weight: ${(props) => props.theme.typography4.fontWeight};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.background};
  }
`;

export const CloseButton = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const SearchPopup = styled.ul`
  --outline-gap: 2px;
  &::before {
    content: ' ';
    width: 100%;
    top: -2px;
    left: 0;
    height: var(--outline-gap);
    position: absolute;
    background-color: ${(props) => props.theme.colors.background};
    display: block;
  }

  width: 100%;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  outline: var(--outline);
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-shadow: var(--box-shadow);
  border-width: 0;
  border-color: ${(props) => props.theme.colors.border};
  border-style: solid;
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.typography4.fontSize};
  font-weight: ${(props) => props.theme.typography4.fontWeight};

  :last-child {
    border-bottom: 0;
  }
  ${breakpoints.tablet} {
    justify-content: center;
    position: relative;
    border-radius: 0;
  }

  ${breakpoints.desktopOrBigger} {
    top: calc(100% + var(--outline-gap));
  }
`;

export const SearchResult = styled.li`
  &::before {
    content: ' ';
    width: calc(100% - var(--height));
    top: 0;
    left: 1rem;
    height: 1px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.borderColor};
    display: block;
  }
  padding: 0.5rem 1rem;
  position: relative;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.typography4.fontWeight};
  cursor: pointer;

  & span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & strong {
    font-weight: 700;
  }

  & .icon {
    margin-right: 0.3rem;
    display: inline-block;
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &:hover,
  &:focus,
  &.selected {
    background-color: ${(props) => props.theme.colors.secondary};
    top: 20;
  }
`;
