import BackOnTopButtonIcon from '@images/arrow-top.svg';

import { BackOnTopButton, ButtonContent, ButtonText, ButtonWrapper } from './styles';

/**
 * @description Scroll to the top button component
 */

interface ScrollToTopButtonProps {
  name: string;
  onClick: () => void;
  landingPage?: boolean;
}

export function ScrollToTopButton({ name, onClick, landingPage = false }: ScrollToTopButtonProps) {
  return (
    <ButtonWrapper $landingPage={landingPage}>
      <BackOnTopButton onClick={onClick}>
        <ButtonContent>
          <BackOnTopButtonIcon />
          <ButtonText>{name}</ButtonText>
        </ButtonContent>
      </BackOnTopButton>
    </ButtonWrapper>
  );
}
