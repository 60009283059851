import { useContext } from 'react';

import { TerrainContext, TerrainContextType } from './TerrainProvider';

export const useTerrainContext = (): TerrainContextType => {
  const context = useContext(TerrainContext);
  if (!context) {
    throw new Error('Called useTerrainContext before setting TerrainProvider context');
  }
  return context;
};
