import Image from 'next/image';

import { t } from 'i18next';

// components
import { SearchBox } from './components/SearchBox';
// styles
import { HeaderContainer, HomeButton, LogoContainer } from './styles';

export interface HeaderProps {
  landingPage?: boolean;
}

export const Header = ({ landingPage = false }: HeaderProps) => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Image src={'/assets/images/logo.svg'} fill alt={t('logoAlt')} />
      </LogoContainer>

      {!landingPage && <SearchBox className="searchBox" />}

      <HomeButton href="https://www.natuurkampeerterreinen.nl">
        <Image src={'/assets/images/home.svg'} width={32} height={32} alt={t('homeButtonAlt')} />
      </HomeButton>
    </HeaderContainer>
  );
};
