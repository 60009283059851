import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const HeaderContainer = styled.div`
  --horizontal-spacing: 1.5rem;
  container-type: normal;
  background-color: ${(props) => props.theme.colors.primary};
  display: grid;
  grid-template-areas: 'logo searchBox languageSelect home';
  grid-template-columns: min-content auto min-content min-content;
  grid-template-rows: 2.5rem;
  align-items: center;
  padding: 0.75rem var(--horizontal-spacing);

  & .searchBox {
    grid-area: searchBox;
    display: block;

    ${breakpoints.tablet} {
      width: calc(
        100cqi - (var(--horizontal-spacing) * 2)
      ); // 100% of container size less the horizontal paddings
    }
  }

  & .languageSelect {
    grid-area: languageSelect;
  }

  ${breakpoints.tablet} {
    grid-template-areas:
      'logo . languageSelect home'
      'searchBox searchBox searchBox searchBox';
    row-gap: 2vw;
    column-gap: 2vw;
    grid-template-rows: 2rem auto;
  }
  ${breakpoints.tabletOrBigger} {
    grid-gap: 1.2rem;
  }
`;

export const HomeButton = styled.a`
  display: flex;
  grid-area: home;
  justify-self: start;
  align-items: center;
`;

export const rightBlock = styled.div`
  grid-area: home;
`;

export const LogoContainer = styled.div`
  grid-area: logo;
  position: relative;
  align-self: stretch;

  width: 5.7rem;

  ${breakpoints.desktopOrBigger} {
    width: 7.125rem;
  }
`;
