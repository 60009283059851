import React from 'react';

import { Icon, IconDiv } from './styles';

// Id's from API properties and it's font values
export const fontIconsMapping = [
  { id: 3, fontValue: 'A' },
  { id: 6, fontValue: 'C' },
  { id: 4, fontValue: 'D' },
  { id: 103, fontValue: 'E' },
  { id: 5, fontValue: 'F' },
  { id: 25, fontValue: 'a' },
  { id: 15, fontValue: 'b' },
  { id: 26, fontValue: 'c' },
  { id: 11, fontValue: 'd' },
  { id: 10, fontValue: 'e' },
  { id: 112, fontValue: 'f' },
  { id: 12, fontValue: 'g' },
  { id: 13, fontValue: 'h' },
  { id: 23, fontValue: 'i' },
  { id: 22, fontValue: 'j' },
  { id: 105, fontValue: 'k' },
  { id: 8, fontValue: 'l' },
  { id: 14, fontValue: 'm' },
  { id: 24, fontValue: 'n' },
  { id: 113, fontValue: 'o' },
  { id: 107, fontValue: 'p' },
  { id: 108, fontValue: 'B' },
  { id: 7, fontValue: 'q' },
  { id: 109, fontValue: 't' },
  { id: 106, fontValue: 'u' },
  { id: 110, fontValue: 'r' },
  { id: 20, fontValue: 's' },
  { id: 21, fontValue: '!' },
  { id: 86, fontValue: '@' },
];

export interface FontIconProps {
  id?: number;
}

/**
 * @description Component for converting property id's
 * /received from API into font icons and displaying them
 */

export const FontIcon: React.FC<FontIconProps> = ({ id }) => {
  const getFontValue = (id: number) => {
    const item = fontIconsMapping.find((item) => item.id === id);
    return item ? item.fontValue : null;
  };

  const fontValue = id ? getFontValue(id) : null;

  return (
    <IconDiv>
      <Icon>{fontValue}</Icon>
    </IconDiv>
  );
};
