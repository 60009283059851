import {
  HeaderText,
  HeaderTitle,
  HeaderWrapper,
  MainColumn,
  PriceItemDescription,
  PriceItemValue,
  StyledList,
  SubColumn,
} from './styles/GridContainer';

export interface PriceItemProps {
  high: string;
  low: string;
  extra: string;
  price: {
    short_name: string;
  };
}
type HeaderLabelType = 'other' | 'persons' | 'equipment';

interface GridSectionProps {
  headerLabel: HeaderLabelType;
  pricing: PriceItemProps[];
}

/**
 * @description Renders a grid with pricing information categorized by header label.
 */

export function GridPriceSection({ pricing, headerLabel }: GridSectionProps) {
  // Function to check if all low or high values are null
  function isValuesNull(pricing: PriceItemProps[], type: 'low' | 'high'): boolean {
    return pricing.every((item) => (type === 'low' ? item.low === null : item.high === null));
  }

  // Determine whether to show LS columns based on whether all low values are null
  const showLowSeasonValues = !isValuesNull(pricing, 'low');

  // Determine whether to show HS columns based on whether all high values are null
  const showHighSeasonValues = !isValuesNull(pricing, 'high');

  // Function to transform low and high values to "gratis" or display the actual value
  function transformLowHigValues(pricing: PriceItemProps[]) {
    return pricing.map((item, index) => {
      const { short_name } = item.price;

      // Display free "gratis" for the kids price section
      const isFree = short_name === 'kind (leeftijd)' && ['0-2', '0-1', '0'].includes(item.extra);

      // Format price with two decimals
      const formatPrice = (price: string): string => {
        return parseFloat(price).toFixed(2);
      };

      // Format price item name (electricity per day)
      const formatElectricityPerDayPrice = (price: string): string => {
        return price.charAt(0).toUpperCase() + price.slice(1);
      };

      const lowSeason =
        showLowSeasonValues && (isFree || item.low === '0.0')
          ? 'gratis'
          : item.low === null
            ? null
            : `€ ${formatPrice(item.low)}`;

      const highSeason =
        showHighSeasonValues && (isFree || item.high === '0.0')
          ? 'gratis'
          : item.high === null
            ? null
            : `€ ${formatPrice(item.high)}`;

      return (
        <SubColumn key={index}>
          <StyledList>
            {short_name === 'elektriciteit per dag' ? (
              <PriceItemDescription $capitalize={false}>
                {formatElectricityPerDayPrice(short_name)}
                {item.extra && `(${item.extra})`}
              </PriceItemDescription>
            ) : (
              <PriceItemDescription $capitalize>
                {short_name}
                {item.extra && `(${item.extra})`}
              </PriceItemDescription>
            )}
          </StyledList>
          <PriceItemValue>{showLowSeasonValues ? lowSeason : ''}</PriceItemValue>
          <PriceItemValue>{showHighSeasonValues ? highSeason : ''}</PriceItemValue>
        </SubColumn>
      );
    });
  }

  return (
    <MainColumn>
      <HeaderWrapper>
        <HeaderTitle>{headerLabel}</HeaderTitle>
        {<HeaderText>{showLowSeasonValues && showHighSeasonValues ? 'LS' : ''}</HeaderText>}
        {<HeaderText>{showHighSeasonValues && showLowSeasonValues ? 'HS' : ''}</HeaderText>}
      </HeaderWrapper>
      {transformLowHigValues(pricing)}
    </MainColumn>
  );
}
