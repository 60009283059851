import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const GridWrapper = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing[3]};
  margin-block:${theme.spacing[3]};

  ${breakpoints.tablet || breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`,
);

export const HeaderTitle = styled.span(
  ({ theme }) => `
  font-family : var(--font-lexend);
  color: ${theme.colors.primary};
  text-align: left;
  font-size:${theme.typography6.fontSize};
  font-weight:${theme.typography5.fontWeight};
  margin-bottom : ${theme.spacing[1]}
`,
);

export const MainColumn = styled.div``;

export const SubColumn = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 5px 10px;
  margin-bottom: 4px;
`;

export const HeaderWrapper = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  padding-bottom: calc(${theme.spacing[2]} - 4px);
`,
);

export const HeaderText = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography5.fontWeight};
`,
);

export const StyledList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 0 1rem;
`;

export const PriceItemDescription = styled.li<{ $capitalize: boolean }>(
  ({ theme, $capitalize }) => `
  text-transform: ${$capitalize ? 'capitalize' : 'none'}; 
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height: ${theme.typography4.lineHeight};
`,
);

export const PriceItemValue = styled.span(
  ({ theme }) => `
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height: ${theme.typography4.lineHeight};
`,
);
