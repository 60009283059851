import { useTranslation } from 'react-i18next';

import { DatesContainer, DatesHeader, DatesLabel } from './styles';

/**
 * @description Display dates when terrain is fully booked
 */

interface FullyBookedProps {
  fully_booked_from: string;
  fully_booked_till: string;
}

export function FullyBookedSection({ fully_booked_from, fully_booked_till }: FullyBookedProps) {
  const { t } = useTranslation('common');

  return (
    <DatesContainer>
      <>
        <DatesHeader>{`${t('fyllyBookedTitle')}:`}</DatesHeader>
        <DatesLabel>{`Van: ${fully_booked_from} tot ${fully_booked_till}`}</DatesLabel>
      </>
    </DatesContainer>
  );
}
