export interface PropertyItem {
  extra?: string;
  property: {
    id: number;
    short_name: string;
  };
}

/**
 * @description Categorizes camping properties.
 * @param pricing An array of PropertyItem objects containing property id and name.
 * @returns An object containing categorized camping properties.
 */
export function categorizeCampingProperties(property: PropertyItem[]) {
  // If pricing data is not provided, return empty arrays for each category
  if (!property)
    return { firstSectionProperties: [], secondSectionProperties: [], thirdSectionProperties: [] };

  // List of properties id's that belongs to spesific section of the page
  const firstSectionIds = [108, 3, 4, 103, 5, 110, 6, 20];
  const secondSectionIds = [12, 13, 22, 105, 112, 108, 106, 21, 10, 86, 23, 11, 24, 7];
  const thirdSectionIds = [26, 14, 8, 15, 25, 113, 107];

  // Create empty arrays to store categorized price items
  const firstSectionProperties: PropertyItem[] = [];
  const secondSectionProperties: PropertyItem[] = [];
  const thirdSectionProperties: PropertyItem[] = [];

  // Categorize properties based on section
  property.forEach((p) => {
    if (firstSectionIds.includes(p.property.id)) {
      firstSectionProperties.push(p);
    } else if (secondSectionIds.includes(p.property.id)) {
      secondSectionProperties.push(p);
    } else if (thirdSectionIds.includes(p.property.id)) {
      thirdSectionProperties.push(p);
    }
  });

  // Return the categorized properties
  return { firstSectionProperties, secondSectionProperties, thirdSectionProperties };
}
