import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/navigation';

import CloseButtonIcon from '@images/CloseButtonIcon.svg';

import { useTerrainContext } from '@/context/useTerrainContext';
import { useTerrain } from '@/hooks/useTerrains';

import { ClientOnly } from '../ClientProviders';
import {
  HeaderTitle,
  ImageComponent,
  LocationDetails,
  PricingSection,
  PropertySection,
  Reviews,
  ScrollToTopButton,
  TerrainDescription,
} from './components';
import { CloseButtonLandingPage } from './components/CloseButtonLandingPage';
import { FullyBookedSection } from './components/FullyBookedSection';
import ShareModal from './components/ShareModal/ShareModal';
import {
  BackButton,
  DetailsContainer,
  DetailsContent,
  DetailsWrapper,
  HeaderContainer,
} from './styles';

export interface DetailsProps {
  landingPage?: boolean;
  terrainNumber?: number;
}

export const Details = ({ landingPage = false, terrainNumber }: DetailsProps) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();
  const { terrain: terrainId, setTerrain } = useTerrainContext();
  const { terrain, isPending } = useTerrain(terrainId ?? null);

  // Scroll to the top of the page
  const scrollToTop = () => {
    const scrollableElement = document.querySelector('#detailsContent');
    if (scrollableElement) {
      scrollableElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (landingPage && terrainNumber) {
      setTerrain(terrainNumber);
    }
  }, [landingPage, setTerrain, terrainNumber]);

  return (
    <ClientOnly>
      <DetailsContainer $visible={Boolean(terrainId)}>
        {!isPending && terrain && (
          <HeaderContainer $landingPage={landingPage}>
            <BackButton
              onClick={() => {
                landingPage ? push('/') : setTerrain(null);
              }}>
              {landingPage ? <CloseButtonLandingPage /> : <CloseButtonIcon />}
            </BackButton>
            <ShareModal id={terrain.id} />
          </HeaderContainer>
        )}
        <DetailsWrapper>
          {(isPending || !terrain) && <ImageComponent id={1} title="" />}
          {!isPending && terrain && (
            <DetailsContent id="detailsContent" $landingPage={landingPage}>
              <HeaderTitle
                name={terrain.name}
                reservation_url={terrain.reservation_url}
                city={terrain.city}
                state={terrain.state.name}
                camping_places={terrain.nature_camping_places}
                open_from={terrain.open_from}
                open_till={terrain.open_till}
                id={terrain.id}
                property={terrain.entity_properties}
                kind={terrain.kind}
              />
              {/* Display fully booked component */}
              {terrain.fully_booked_from && terrain.fully_booked_till && (
                <FullyBookedSection
                  fully_booked_from={terrain.fully_booked_from}
                  fully_booked_till={terrain.fully_booked_till}
                />
              )}
              <ImageComponent id={terrain.id} title={terrain.name} images={terrain.photos} />
              <TerrainDescription text={terrain?.terrain} />
              <PropertySection property={terrain.entity_properties} />
              <PricingSection
                pricing={terrain.entity_prices}
                highseason={terrain.highseason}
                comment_prices={terrain.comment_prices}
              />
              <LocationDetails id={terrain.id} />
              <Reviews id={terrain.id} />
            </DetailsContent>
          )}
          <ScrollToTopButton
            name={t('backOnTopButton')}
            onClick={scrollToTop}
            landingPage={landingPage}
          />
        </DetailsWrapper>
      </DetailsContainer>
    </ClientOnly>
  );
};
