import { DetailedReviews } from './api-dtos';

export const SortKeys = ['mostRecents', 'hightoLow', 'lowToHigh'] as const;
export type SortKeys = (typeof SortKeys)[number];

const sortByNewestDate = (a: DetailedReviews, b: DetailedReviews) =>
  a.created_at.localeCompare(a.created_at);
const sortByHighNotes = (a: DetailedReviews, b: DetailedReviews) => b.rating - a.rating;
const sortByLowerNotes = (a: DetailedReviews, b: DetailedReviews) => a.rating - b.rating;

export const sortFunctions: Record<SortKeys, (a: DetailedReviews, b: DetailedReviews) => number> = {
  mostRecents: sortByNewestDate,
  hightoLow: sortByHighNotes,
  lowToHigh: sortByLowerNotes,
};
