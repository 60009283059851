import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CaretIcon from '@images/carteIcon.svg';

import { RatingContainer, SingleRatingStar } from '@/components/List/styles/ListCard';
import { useReviews } from '@/hooks/useTerrains';
import { SortKeys } from '@/lib/detailedReviewSorter';
import { roundAverageRating } from '@/utils/roundAverageRating';

import { AddReview } from './AddReview';
import {
  AuthorName,
  ChangeSortButton,
  NoReviewsCard,
  ReadMoreButton,
  ReviewDate,
  ReviewDescription,
  ReviewsCard,
  ReviewsCardFirstLine,
  ReviewsContainer,
  ShowMoreButton,
  SortButton,
  SortButtons,
  SortDropdown,
  StarsBlock,
  Title,
  TitleContainer,
} from './styles/ReviewsContainer';

interface ReviewsProps {
  id: number;
}

const dateFormat = (date: string, dateFormatter: Intl.DateTimeFormat) => {
  date = date.replaceAll('-', '/');
  return dateFormatter.format(new Date(date));
};

export function Reviews({ id }: ReviewsProps) {
  const { t } = useTranslation('common');
  const [sortKey, setSortKey] = useState<SortKeys>('mostRecents');
  const reviewsPerPage = 3;
  const [visibleReviews, setVisibleReviews] = useState<number>(reviewsPerPage);
  const [showFullDescriptions, setShowFullDescriptions] = useState<boolean[]>([]);
  const [showSortOptions, setShowSortOptions] = useState(false);

  const dateFormatter = new Intl.DateTimeFormat(t('locale'), { dateStyle: 'short' });
  const { isPending, reviews, addReview } = useReviews(id, sortKey);

  const handleChosenSortOptions = (sortKey: SortKeys) => {
    setSortKey(sortKey);
    setShowSortOptions(false);
  };

  // Function to toggle showFullDescriptions for a specific index
  const toggleShowFullDescription = (index: number) => {
    setShowFullDescriptions((prev) => {
      const newShowFullDescriptions = [...prev];
      newShowFullDescriptions[index] = !newShowFullDescriptions[index];
      return newShowFullDescriptions;
    });
  };

  const showMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + reviewsPerPage);
  };

  if (isPending || !reviews) return null;

  return (
    <>
      <ReviewsContainer>
        {reviews.length >= 1 ? (
          <TitleContainer>
            <Title>
              <h2>{t('reviewsTitle')}</h2>
              <RatingContainer>
                <SingleRatingStar
                  src={'/assets/images/ratingStar.svg'}
                  alt="rating green star"
                  width="15"
                  height="14"
                />
                <span> {roundAverageRating(reviews)}</span>
              </RatingContainer>
            </Title>
            <SortDropdown>
              <SortButton onClick={() => setShowSortOptions(!showSortOptions)}>
                {t(sortKey)}
                <CaretIcon />
              </SortButton>
              {showSortOptions && (
                <SortButtons>
                  {SortKeys.map((sortKeyToChoose) => (
                    <ChangeSortButton
                      key={sortKeyToChoose}
                      className={sortKey === sortKeyToChoose ? 'selected' : ''}
                      onClick={() => handleChosenSortOptions(sortKeyToChoose)}>
                      {t(sortKeyToChoose)}
                      <CaretIcon />
                    </ChangeSortButton>
                  ))}
                </SortButtons>
              )}
            </SortDropdown>
          </TitleContainer>
        ) : (
          <>
            <TitleContainer>
              <Title>
                <h2>{t('reviewsTitle')}</h2>
              </Title>
            </TitleContainer>
            <NoReviewsCard>
              <ReviewsCardFirstLine>
                <strong>{t('noReviewsTitle')}</strong>
              </ReviewsCardFirstLine>
              <ReviewDescription>{t('noReviewsDescription')}</ReviewDescription>
            </NoReviewsCard>
          </>
        )}
        {reviews.slice(0, visibleReviews).map((review, index) => (
          <ReviewsCard key={`review-${index}`}>
            <ReviewsCardFirstLine>
              <AuthorName>
                <strong>{review.author}</strong>
                <StarsBlock>
                  {Array.from({ length: review.rating }, (_, starIndex) => (
                    <SingleRatingStar
                      key={`star-${starIndex}`}
                      src={'/assets/images/ratingStar.svg'}
                      alt={`rating green star ${starIndex + 1}`}
                      width="15"
                      height="14"
                    />
                  ))}
                </StarsBlock>
              </AuthorName>
              <ReviewDate>{dateFormat(review.created_at, dateFormatter)}</ReviewDate>
            </ReviewsCardFirstLine>
            <ReviewDescription>
              {showFullDescriptions[index] || review.description.length < 200
                ? review.description // Show full description if showFullDescriptions is true
                : review.description.slice(0, 200) + '...'}
              {/* Display first 100 characters */}
            </ReviewDescription>
            {review.description.length >= 200 && (
              <ReadMoreButton onClick={() => toggleShowFullDescription(index)}>
                {showFullDescriptions[index] ? t('readLessButton') : t('readMoreButton')}
              </ReadMoreButton>
            )}
          </ReviewsCard>
        ))}
        {reviews.length > visibleReviews && (
          <ShowMoreButton onClick={showMoreReviews}>{t('showMoreButton')}</ShowMoreButton>
        )}
      </ReviewsContainer>
      <AddReview id={id} addReview={addReview} />
    </>
  );
}
