export interface PriceItem {
  low: string;
  high: string;
  extra: string;
  price: {
    short_name: string;
  };
}

/**
 * @description Categorizes camping prices based on they names
 */

export function filterCampingPrice(pricing: PriceItem[]) {
  // If pricing data is not provided or empty, return empty arrays for each category
  if (!pricing || pricing.length === 0) {
    return { personsPrice: [], equipmentPrice: [], otherPrice: [] };
  }

  // Define mapping of short names to category labels
  const categoryMapping: [string, keyof typeof categorizedPrices][] = [
    ['kind (leeftijd)', 'personsPrice'],
    ['huisdier', 'personsPrice'],
    ['volwassene', 'personsPrice'],
    ['extra volwassene', 'personsPrice'],
    ['extra kind (leeftijd)', 'personsPrice'],
    ['tent', 'equipmentPrice'],
    ['bijzet-tent', 'equipmentPrice'],
    ['caravan', 'equipmentPrice'],
    ['vouwwagen', 'equipmentPrice'],
    ['kampeerbusje', 'equipmentPrice'],
    ['kampeerauto/camper', 'equipmentPrice'],
  ];

  // Create an object to store categorized price items with default values
  const categorizedPrices: { [key: string]: PriceItem[] } = {
    personsPrice: [],
    equipmentPrice: [],
    otherPrice: [],
  };

  // Iterate through the pricing items and categorize them
  pricing.forEach((priceItem) => {
    const shortName = priceItem.price.short_name;
    const category = categoryMapping.find(([name]) => name === shortName)?.[1] || 'otherPrice';

    // Ensure that the category exists in the categorizedPrices object
    if (!(category in categorizedPrices)) {
      categorizedPrices[category] = [];
    }

    // Push the price item to the corresponding category array
    categorizedPrices[category]?.push({
      ...priceItem,
      price: { ...priceItem.price, short_name: shortName },
    });
  });

  return categorizedPrices;
}
