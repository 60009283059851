import { Location } from '@/lib/api-dtos';
import fetcher from '@/utils/fetcher';

export interface TerrainParams extends Record<string, string> {
  terrain: string; // search
}

export class LocationService {
  async list(): Promise<Location[]> {
    return fetcher<Location[]>({
      url: '/api/proxy/states?by_type=all_terrains',
    });
  }
}
