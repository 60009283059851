import styled from 'styled-components';

export const ShareModalContainer = styled.div`
  display: flex;
  border: none;
  background-color: transparent;
  position: relative;
  justify-content: end;
`;
export const OpenShareModalContainer = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ShareOptions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.border};
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  width: 9.25rem;
  gap: 0.75rem;
`;

export const ShareButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  cursor: pointer;
  border: none;
`;
export const ShareOptionsTitle = styled.span`
  margin-left: 0.75rem;
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
  color: ${(props) => props.theme.colors.text};
`;
