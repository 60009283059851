const size = {
  mobile: '500px',
  tablet: '768px',
  desktop: '1024px',
};

export const breakpoints = {
  mobile: `@media (max-width: ${size.mobile})`,
  tablet: `@media (max-width: ${size.tablet})`,
  tabletOrBigger: `@media (min-width: ${size.tablet})`,
  desktopOrBigger: `@media (min-width: ${size.desktop})`,
  // Separate breakpoints used for different tablet sizes
  responsiveTablet: `@media (min-width: ${size.tablet}) and (max-width: ${size.desktop})`,
};
