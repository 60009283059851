import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { useTerrain } from '@/hooks/useTerrains';
import { AddReview } from '@/lib/api-dtos';

import { RatingStarComponent } from './RatingStar';
import {
  AddReviewsContainer,
  AddReviewsContent,
  AddReviewTitle,
  ConfirmationMessage,
  ConfirmationMessageContainer,
  EmailMessage,
  FeedbackMessage,
  InfosContainer,
  Input,
  InputLabel,
  RatingContainer,
  RatingMessage,
  ReviewTextArea,
  SubmitButton,
} from './styles';

interface AddReviewsProps {
  id: number;
  addReview: (newReview: AddReview) => Promise<void>;
}

export function AddReview({ id, addReview }: AddReviewsProps) {
  const { t } = useTranslation('common');
  const { isPending, terrain } = useTerrain(id);
  const campingName = terrain?.name;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [author, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [token, setToken] = useState<string | null>(null);

  if (isPending || !terrain) return null;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    if (rating === 0) {
      setFeedbackMessage(t('atLeastOneStart'));
      return;
    }

    if (!token) {
      setFeedbackMessage(t('invalidRecaptcha'));
      return;
    }

    const reviewData = { author, email, description, rating, token };

    // Call the API to add the review
    try {
      await addReview(reviewData);
      setIsSubmitted(true);
      // Reset form after successful submission
      setName('');
      setEmail('');
      setReview('');
      setRating(0);
      setToken(null);
      recaptchaRef.current?.reset();
    } catch (error) {
      console.error('Error submitting review:', error);
      setFeedbackMessage(t('feedbackMessageError')); // Set error feedback message
    }
  };

  const onChangeToken = (value: string | null) => {
    setToken(value);
  };

  return (
    <AddReviewsContainer>
      <AddReviewTitle>{t('addReviewTitle')}</AddReviewTitle>
      <AddReviewsContent onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit}>
        {!isSubmitted ? (
          <>
            <InputLabel htmlFor="name">{t('inputName')}</InputLabel>
            <Input
              required
              type="text"
              id="name"
              value={author}
              onChange={(e) => setName(e.target.value)}
            />
            <InputLabel htmlFor="email">{t('inputEmail')}</InputLabel>
            <Input
              required
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputLabel htmlFor="review">{t('reviewInput')}</InputLabel>
            <ReviewTextArea
              required
              name="Review"
              value={description}
              rows={10}
              cols={30}
              onChange={(e) => setReview(e.target.value)}
            />
            <InfosContainer>
              <RatingContainer>
                <RatingMessage>
                  {t('ratingInputPart1')}
                  <strong>{campingName}</strong>
                </RatingMessage>
                <RatingStarComponent value={rating} onChange={(value) => setRating(value)} />
                <RatingMessage>{t('ratingInputPart2')}</RatingMessage>
              </RatingContainer>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
                onChange={onChangeToken}
              />
              <SubmitButton>{t('postReviewButton')}</SubmitButton>
              {feedbackMessage && <FeedbackMessage>{feedbackMessage}</FeedbackMessage>}
              <EmailMessage>{t('emailMessageInformation')}</EmailMessage>
            </InfosContainer>
          </>
        ) : (
          <>
            <ConfirmationMessageContainer>
              <ConfirmationMessage $title>{t('feedbackMessageSuccessTitle')}</ConfirmationMessage>
              <ConfirmationMessage $title={false}>
                {t('feedbackMessageSuccess')}
              </ConfirmationMessage>
            </ConfirmationMessageContainer>
          </>
        )}
      </AddReviewsContent>
    </AddReviewsContainer>
  );
}
