import { useQuery } from '@tanstack/react-query';

import { Location } from '@/lib/api-dtos';
import { ApiError } from '@/lib/api-error';
import { LocationService } from '@/services/LocationService';

export const useLocations = () => {
  // error handling is not implemented yet
  const { data, isPending } = useQuery<Location[], ApiError>({
    queryKey: ['locations'],
    queryFn: () => new LocationService().list(),
  });

  return { locations: data, isPending };
};
