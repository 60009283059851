import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopiedLink from '@images/copiedLink.svg';
import EmailIcon from '@images/EmailOutlined.svg';
import ShareOutline from '@images/share-outline1.svg';
import ShareIconButtonGreen from '@images/ShareIconButtonGreen.svg';
import WhatsappIcon from '@images/WhatsappRounded.svg';

import { useIsMobile } from '@/hooks/useIsMobile';
import { useTerrain } from '@/hooks/useTerrains';

import {
  OpenShareModalContainer,
  ShareButton,
  ShareModalContainer,
  ShareOptions,
  ShareOptionsTitle,
} from './ShareModalContainer';

interface ShareModalProps {
  id: number;
}

/**
 * @description Share modal for the user shre the application link Render
 * based on whether it's a mobile device or not
 */

export const ShareModal: React.FC<ShareModalProps> = ({ id }: ShareModalProps) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const appURL = window.location.href;
  const { isPending, terrain } = useTerrain(id);
  const [copyToclipBoard, setCopyToclipBoard] = useState(false);
  const [isOpened, setOpened] = useState(false);

  // useEffect to handle clicks outside the share modal to close it
  useEffect(() => {
    if (!isOpened) return;

    const handleClickOutside = (event: MouseEvent) => {
      const shareModalContainer = document.querySelector('.ShareModalContainer');
      if (shareModalContainer && !shareModalContainer.contains(event.target as Node)) {
        setOpened(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpened]);

  // Function to handle native sharing using the Web Share API
  const handleNativeShare = () => {
    if (!navigator.share) return alert(t('notSuported'));
    navigator
      .share({
        title: terrain?.name,
        text: t('shareMessage'),
        url: window.location.href,
      })
      .catch((error) => console.error('Error sharing:', error));
  };

  // Function to handle sharing via WhatsApp
  const handleWhatsAppShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    const message = `${t('shareMessage')}:\n${appURL} `;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, '_blank');
    event.stopPropagation();
  };

  const shareAndCopyLink = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(appURL);
      setCopyToclipBoard(true);
      alert(t('copyToClipboardSuccess'));
    } catch (error) {
      console.error(error);
    }
  };

  // Function to share and copy the link to the clipboard
  const handleMailShare = () => {
    const subject = t('shareMessage');
    const body = appURL;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body,
    )}`;

    window.location.href = mailtoLink;
  };

  // If the terrain information is pending or not available, return null
  if (isPending || !terrain) return null;

  return isMobile ? (
    <OpenShareModalContainer onClick={handleNativeShare}>
      <ShareOutline onClick={handleNativeShare} />
    </OpenShareModalContainer>
  ) : (
    <ShareModalContainer className="ShareModalContainer">
      <OpenShareModalContainer>
        {isOpened ? (
          <ShareIconButtonGreen onClick={() => setOpened(false)} />
        ) : (
          <ShareOutline onClick={() => setOpened(true)} />
        )}
        {isOpened && (
          <ShareOptions>
            <ShareButton onClick={handleWhatsAppShare}>
              <WhatsappIcon />
              <ShareOptionsTitle>WhatsApp</ShareOptionsTitle>
            </ShareButton>
            <ShareButton onClick={handleMailShare}>
              <EmailIcon />
              <ShareOptionsTitle>E-mail</ShareOptionsTitle>
            </ShareButton>
            <ShareButton onClick={shareAndCopyLink}>
              {copyToclipBoard ? <CopiedLink fill="#6AC788" /> : <CopiedLink fill="123B2B" />}
              <ShareOptionsTitle>{t('copyToClipboard')}</ShareOptionsTitle>
            </ShareButton>
          </ShareOptions>
        )}
      </OpenShareModalContainer>
    </ShareModalContainer>
  );
};

export default ShareModal;
