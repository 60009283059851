import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const ButtonText = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.typography3.fontSize};
  font-weight: ${theme.typography3.fontWeight};
`,
);
