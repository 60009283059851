import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontIcon } from '@/components/FontIconHelper';
import { useIsMobile } from '@/hooks/useIsMobile';

import {
  ColumnWrapper,
  Content,
  GridContainer,
  HeaderTitle,
  HeaderWrapper,
  IconContainer,
  ShowButton,
  Text,
} from './styles/GridContainer';

interface PropertyItemProps {
  extra?: string;
  property: {
    id: number;
    short_name: string;
  };
}

interface GridSectionProps {
  headerLabel: string;
  property: PropertyItemProps[];
  divider: number;
  mobileDivider: number;
}

/**
 * @description Render a grid layout for displaying property items.
 * On mobile devices, it shows 2 items per column, while on larger screens,
 * it uses the specified divider value to determine the number of items per column.
 * Includes a toggle button to show/hide additional properties beyond the initial display.
 */

export function GridSection({ headerLabel, property, divider, mobileDivider }: GridSectionProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('common');
  const [showMoreProperties, setShowMoreProperties] = useState(false);

  // Toggle to show more properties
  const toggleShowMoreProperties = () => {
    setShowMoreProperties(!showMoreProperties);
  };

  // Show on mobile screen 2 items per each column
  const amountOfItems = isMobile ? mobileDivider : divider;

  // Render a grid column
  function renderGridColumn(column: PropertyItemProps[]) {
    return (
      <>
        {column.map((p, i) => {
          if (i < amountOfItems || showMoreProperties) {
            return (
              <Content key={p.property.id}>
                <IconContainer>
                  <FontIcon id={p.property.id} />
                </IconContainer>
                <Text>
                  {p.property.short_name}
                  {p.extra ? ` ${p.extra}` : ''}
                </Text>
              </Content>
            );
          }
        })}
      </>
    );
  }

  return (
    <ColumnWrapper>
      <HeaderWrapper>
        <HeaderTitle>{headerLabel}</HeaderTitle>
      </HeaderWrapper>
      <GridContainer>{renderGridColumn(property)}</GridContainer>
      {property.length > amountOfItems && (
        <ShowButton onClick={toggleShowMoreProperties}>
          {showMoreProperties ? t('showLessProperties') : t('showMoreProperties')}
        </ShowButton>
      )}
    </ColumnWrapper>
  );
}
