import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GridPriceSection, PriceItemProps } from './GridPriceSection';
import { filterCampingPrice } from './priceUtils';
import {
  CommentPriceSection,
  ExplanationSection,
  GridWrapper,
  HighSeasonContainer,
  HighSeasonContent,
  PricingContainer,
  PricingContent,
  ShowButton,
  Subtitle,
  Title,
} from './styles';

interface PricingProps {
  pricing: PriceItemProps[];
  highseason?: string;
  comment_prices: string;
}

/**
 * @description Render Pricing Section with low and high season
 *
 */

export function PricingSection({ pricing, highseason, comment_prices }: PricingProps) {
  const [showPricing, setShowPricing] = useState(false);
  const { t } = useTranslation('common');

  // Format the high season dates
  const formattedHighseasonValue = highseason
    // Replace date ranges like 'MM/DD - MM/DD' with consistent formatting
    ?.replace(/(\d{2}\/\d{2})\s*-\s*(\d{2}\/\d{2})/g, '$1 - $2')
    // Replace line breaks with semicolons and spaces
    .replace(/\r?\n/g, '; ')
    // Replace any trailing semicolon and space with a period
    .replace(/; $/, '.');

  // Toogle to show or hide pricing table
  const toggleShowPricing = () => {
    setShowPricing(!showPricing);
  };

  // Categorize pricing data to render it on the page
  const categorisedPrice = filterCampingPrice(pricing);

  // Function to remove HTML tags (<p>, <br>) and '&' entities from text
  const removeHtmlTags = (text: string) => {
    // Remove <p> and </p> tags
    const withoutPTags = text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
    // Remove <br> and <br /> tags
    const withoutBrTags = withoutPTags.replace(/<br\s*\/?>/g, '');
    // Remove '&' entities
    const withoutEntities = withoutBrTags.replace(/&/g, '');
    return withoutEntities;
  };

  return (
    <PricingContainer>
      <Title>{t('pricing')}</Title>
      {showPricing && (
        <PricingContent>
          {highseason && (
            <HighSeasonContainer>
              <Subtitle>{t('highseason')}</Subtitle>
              <HighSeasonContent>{formattedHighseasonValue}</HighSeasonContent>
            </HighSeasonContainer>
          )}
          <GridWrapper>
            {categorisedPrice.personsPrice && categorisedPrice.personsPrice.length > 0 && (
              <GridPriceSection
                headerLabel={t('personsPrice')}
                pricing={categorisedPrice.personsPrice as PriceItemProps[]}
              />
            )}
            {categorisedPrice.equipmentPrice && categorisedPrice.equipmentPrice.length > 0 && (
              <GridPriceSection
                headerLabel={t('equipmentPrice')}
                pricing={categorisedPrice.equipmentPrice as PriceItemProps[]}
              />
            )}
            {categorisedPrice.otherPrice && categorisedPrice.otherPrice.length > 0 && (
              <GridPriceSection
                headerLabel={t('otherPrice')}
                pricing={categorisedPrice.otherPrice as PriceItemProps[]}
              />
            )}
          </GridWrapper>
          <CommentPriceSection>{removeHtmlTags(comment_prices)}</CommentPriceSection>
          <ExplanationSection>{t('priceExplanation')}</ExplanationSection>
        </PricingContent>
      )}
      <ShowButton onClick={toggleShowPricing}>
        {showPricing ? `${t('hideRates')}..` : ` ${t('showRates')}`}
      </ShowButton>
    </PricingContainer>
  );
}
