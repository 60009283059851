import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const DetailsContainer = styled.div<{ $visible: boolean }>(
  ({ theme, $visible }) => `
  display: flex;
  flex: ${$visible ? '1' : '0'} 0 0;
  transition: flex 0.25s ease-out;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.colors.background};
  overflow: hidden;
  width: 100%;
`,
);
export const HeaderContainer = styled.div<{ $landingPage: boolean }>(
  ({ theme, $landingPage = false }) => `
  display: flex;
  width: inherit;
  max-width: 800px;
  background-color: ${theme.colors.background};
  justify-content: space-between;
  height: 56px;

  padding-top:${theme.spacing[2]};
  padding-bottom:${theme.spacing[2]};
  padding-inline:${theme.spacing[3]};
  
  margin-left: ${$landingPage ? 'auto' : '0'};
  margin-right: ${$landingPage ? 'auto' : '0'};

  ${breakpoints.mobile}{
    padding-block:${theme.spacing[1]};
    padding-inline: calc(${theme.spacing[2]} - 4px);
  }
`,
);

export const BackButton = styled.div`
  border: none;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div(
  ({ theme }) => `
  overflow: auto;
  width: 100%;
  padding-bottom:${theme.spacing[2]};
  padding-inline:${theme.spacing[3]};

  ${breakpoints.mobile}{
    padding-block:${theme.spacing[1]};
    padding-inline: calc(${theme.spacing[2]} - 4px);
  }
`,
);

export const DetailsContent = styled.div<{ $landingPage: boolean }>(
  ({ theme, $landingPage = false }) => `
  max-width: calc(800px - ${theme.spacing[4]});
  margin-left: ${$landingPage ? 'auto' : '0'};
  margin-right: ${$landingPage ? 'auto' : '0'};
`,
);
