import { Review } from '@/lib/api-dtos';

// Calculate the average rating
export function roundAverageRating(reviews: Review[]) {
  const totalRatings = reviews.length;
  const sumOfRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = totalRatings > 0 ? sumOfRatings / totalRatings : 0;

  return parseFloat(averageRating.toFixed(1));
}
