import styled from 'styled-components';

export const LocationContainer = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const Title = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.typography2.fontSize};
  font-family: var(--font-lexend);
  font-weight: 600;
  margin: 0;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
export const Subtitle = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.typography6.fontSize};
  font-family: var(--font-lexend);
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

export const InfosContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  flex-direction: column;
  margin-bottom: 1.5rem;

  & .route_button {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    font-size: ${(props) => props.theme.typography3.fontSize};
    font-weight: 400;
  }
`;
export const ContactDetails = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  flex-direction: column;
  width: 100%;
  font-size: ${(props) => props.theme.typography3.fontSize};
`;

export const MoreInfo = styled.div`
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
  & a {
    text-decoration: underline;
  }
`;

export const LocationDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionText = styled.p`
  display: block;
  color: ${(props) => props.theme.colors.text};
  margin: 0;
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
`;

export const LocationInformationList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  color: ${(props) => props.theme.colors.text};
`;

export const LocationInformationItem = styled.li`
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
  color: ${(props) => props.theme.colors.text};

  & strong {
    color: ${(props) => props.theme.colors.text};
    margin-inline-start: 5px;
  }
`;

export const IconsContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap:${theme.spacing[2]};
  margin-top: calc(${theme.spacing[2]} - 4px);
`,
);
