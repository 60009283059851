import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const ColumnWrapper = styled.div(
  ({ theme }) => `
  display:flex;
  flex-direction : column;
  margin-bottom: ${theme.spacing[3]} ;
`,
);

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 24px;

  ${breakpoints.tablet || breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const HeaderTitle = styled.span(
  ({ theme }) => `
  font-family : var(--font-lexend);
  color: ${theme.colors.primary};
  text-align: left;
  font-size:${theme.typography6.fontSize};
  font-weight:${theme.typography5.fontWeight};
  margin-bottom : cacl(${theme.spacing[2]} - 4px)
`,
);

export const HeaderWrapper = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: calc(${theme.spacing[2]} - 4px);
`,
);

export const HeaderText = styled.span(
  ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography5.fontWeight};
`,
);

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div(
  ({ theme }) => `
  width: 26px;
  flex-shrink: 0;
  margin-right: ${theme.spacing[1]};
`,
);

export const Text = styled.span(
  ({ theme }) => `
  flex:1;
  color: ${theme.colors.text};
  font-size: ${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height:${theme.typography4.lineHeight};
`,
);

export const ShowButton = styled.button(
  ({ theme }) => `
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  color: ${theme.colors.text};
  font-size: ${theme.typography5.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height:${theme.typography4.lineHeight};
  margin-top:${theme.spacing[2]};
`,
);
