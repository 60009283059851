import Image from 'next/image';

import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const ImageWrapper = styled.div(
  ({ theme }) => `
  max-width: 800px;
  grid-gap: ${theme.spacing[1]};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-block: ${theme.spacing[1]};

  ${breakpoints.tablet || breakpoints.mobile} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`,
);

export const BigImage = styled.div<{ $isMobile: boolean }>(
  ({ theme, $isMobile }) => `
  display: flex;
  grid-row: span 2;
  grid-column: span 2;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  line-height: 0;
  height : ${$isMobile ? '216px' : '320px'};
`,
);

export const SmallImages = styled.div<{ $isMobile: boolean }>(
  ({ theme, $isMobile }) => `
  display: flex;
  grid-row-start: auto;
  position: relative;
  margin: 0;
  padding: 0;
  height : ${$isMobile ? '104px' : '156px'};
`,
);

export const CustomImageHolder = styled.div`
  border-radius: 4px;
  max-width: 100%;
  width: 100% !important;
  height: unset !important;
  margin: 0;
  padding: 0;
  line-height: 0;
  background-color: #e9e9e9;
  position: relative !important;
`;
export const CustomImage = styled(Image)`
  border-radius: 4px;
  max-width: 100%;
  width: 100% !important;
  position: relative !important;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
`;

export const ShowAllButton = styled.button(
  ({ theme }) => `
  position: absolute;
  bottom: ${theme.spacing[1]};
  left: ${theme.spacing[1]};

  padding: 8px 4px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(18, 59, 43, 0.2);
  background: rgba(255, 255, 255, 0.8);
`,
);

export const ButtonContent = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing[1]};
  color:${theme.colors.text};
  font-size:${theme.typography4.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height: ${theme.typography4.lineHeight};
`,
);
