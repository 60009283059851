import { useEffect, useRef, useState } from 'react';

import CloseButtonIcon from '@images/CloseButtonIcon.svg';

import { getImageUrl } from '@/utils/getImageUrl';

import { BackButton } from '../../styles';
import { ImageData } from '../ImageComponent/ImageComponent';
import ShareModal from '../ShareModal/ShareModal';
import {
  BigImageWrapper,
  HeaderButtonContainer,
  ImageQuantityTitle,
  ImagesContainer,
  ModalTitleContainer,
  ModalWindow,
  SmallImage,
  SmallImageWrapper,
  Title,
  WindowContainer,
} from './styles';

/**
 * @description Image component that renders big and small image galleries
 */

export interface ImageGalleryProps {
  id: number;
  title: string;
  imageQuantity: number;
  images: ImageData[];
  onClose: () => void;
}

export function ImageGallery({ id, title, imageQuantity, images, onClose }: ImageGalleryProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const bigImageRef = useRef<HTMLDivElement>(null);
  const smallImageWrapperRef = useRef<HTMLDivElement>(null);

  // Handler for click on small image
  const handleImageClick = (index: number) => {
    // If the clicked image is already active, return
    if (index === activeIndex) return;
    setActiveIndex(index);

    // Scroll to the corresponding big image
    const bigImage = document.querySelector(`#big-image-${index}`);
    bigImage?.scrollIntoView({ behavior: 'instant', inline: 'start', block: 'start' });
  };

  // Function to get the index of the first visible child element
  const getFirstIndex = (element: HTMLDivElement | null) => {
    let index = 0;
    for (const image of element?.children || []) {
      if (image.getBoundingClientRect().x > 0) {
        break;
      }
      index++;
    }
    return index;
  };

  // Update active index when scrolling the big image wrapper
  useEffect(() => {
    const onScroll = () => {
      const index = getFirstIndex(bigImageRef.current);
      setActiveIndex(index);
    };
    const bigImageWrapper = bigImageRef.current;
    bigImageWrapper?.addEventListener('scroll', onScroll);
    return () => {
      bigImageWrapper?.addEventListener('scroll', onScroll);
    };
  }, [bigImageRef]);

  // Scroll to the corresponding small image when the index changes
  useEffect(() => {
    const smallImage = document.querySelector(`#small-image-${activeIndex}`);
    smallImage?.scrollIntoView({ behavior: 'instant', inline: 'center', block: 'center' });
  }, [activeIndex]);

  return (
    <WindowContainer>
      <ModalWindow>
        <HeaderButtonContainer>
          <BackButton onClick={onClose}>
            <CloseButtonIcon />
          </BackButton>
          <ShareModal id={id} />
        </HeaderButtonContainer>
        <ModalTitleContainer>
          <Title>{title}</Title>
          <ImageQuantityTitle>{`${imageQuantity} foto's`}</ImageQuantityTitle>
        </ModalTitleContainer>
        <ImagesContainer>
          <BigImageWrapper ref={bigImageRef}>
            {images.map((image, index) => (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                id={`big-image-${index}`}
                key={index}
                loading="lazy"
                src={getImageUrl(image.large_url)}
                alt={image.alt_text || image.name}
              />
            ))}
          </BigImageWrapper>
          <SmallImageWrapper ref={smallImageWrapperRef}>
            {images.map((image, index) => (
              <SmallImage
                id={`small-image-${index}`}
                key={index}
                width="104"
                height="104"
                src={getImageUrl(image.thumb_url)}
                alt={image.alt_text || image.name}
                onClick={() => handleImageClick(index)}
                $active={index === activeIndex}
              />
            ))}
          </SmallImageWrapper>
        </ImagesContainer>
      </ModalWindow>
    </WindowContainer>
  );
}
