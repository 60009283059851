import { useTranslation } from 'react-i18next';

import { EntityProperties } from '@/lib/api-dtos';

import { GridSection } from '.';
import { categorizeCampingProperties } from './propertyUtils';
import { PropertyContainer, PropertyContent, Title } from './styles';

interface PropertyItemProps {
  extra?: string;
  property: {
    id: number;
    short_name: string;
  };
}
interface PropertyProps {
  property: EntityProperties[];
}

/**
 * @description Property Section
 *
 */

export function PropertySection({ property }: PropertyProps) {
  const { t } = useTranslation('common');

  // Divide properties into three section
  const campingProperties = categorizeCampingProperties(property as PropertyItemProps[]);

  return (
    <PropertyContainer>
      <Title>{t('propertyTitle')}</Title>
      <PropertyContent>
        {campingProperties.firstSectionProperties?.length > 0 && (
          <GridSection
            headerLabel={t('propertySectionOne')}
            property={campingProperties.firstSectionProperties}
            divider={8}
            mobileDivider={8}
          />
        )}
        {campingProperties.secondSectionProperties.length > 0 && (
          <GridSection
            headerLabel={t('propertySectionTwo')}
            property={campingProperties.secondSectionProperties}
            divider={10}
            mobileDivider={4}
          />
        )}
        {campingProperties.thirdSectionProperties.length > 0 && (
          <GridSection
            headerLabel={t('propertySectionThree')}
            property={campingProperties.thirdSectionProperties}
            divider={4}
            mobileDivider={4}
          />
        )}
      </PropertyContent>
    </PropertyContainer>
  );
}
