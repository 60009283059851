import { FontIcon } from '@/components/FontIconHelper';
import { EntityProperties } from '@/lib/api-dtos';

import { categorizeCampingProperties, PropertyItem } from '../PropertySection/propertyUtils';
import { IconsContainer } from './styles';

/**
 * @description Render header icon section
 */

export interface HeaderIconComponentProps {
  property: (PropertyItem | EntityProperties)[];
}

export function IconsSection({ property }: HeaderIconComponentProps) {
  // Filter out non-PropertyItem objects from the property array
  const propertyItems = property.filter((item): item is PropertyItem => 'property' in item);

  // Get first Section properties to display the icons
  const iconsArray = categorizeCampingProperties(propertyItems);

  return (
    <IconsContainer>
      {iconsArray.firstSectionProperties.map((icon) => (
        <div key={icon.property.id}>
          <FontIcon id={icon.property.id} />
        </div>
      ))}
    </IconsContainer>
  );
}
